$primary-color: #ff6200;
$spacers: 10;

@media only screen and (max-width: 600px) {
  #home {
    background-size: cover !important;
  }
}
.text-primary {
  color: $primary-color !important;
}

@media (max-width: 767px) {
  .hero-area {
    padding: 120px 0 0px 0;
  }
}

#home {
  background-origin: content-box;
  background-position: right !important;
  padding-top: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}

#footer {
  background-position: center; /* center the image */
  background-repeat: no-repeat; /* do not repeat the image */
  background-size: cover;
}

.header.navbar-area {
  position: sticky;
}

.hero-area {
  background-position: right;
  background-size: cover;
  color: black !important;

  .hero-content {
    h1,
    p {
      color: black;
    }
    h1 {
      font-size: 50px;
    }
  }
}

.header .navbar .navbar-nav .nav-item a.active {
  color: black;
}

.goal-card {
  border-color: transparent;
  .row {
    margin: 0;
  }
  .problem-card {
    border-radius: 15px;

    background: #a15ba4;
    color: white;
    h6 {
      color: white;
    }
  }
}

.text-black {
  color: black !important;
}

.text-left {
  text-align: left !important;
}

@for $i from 1 through 100 {
  .radius-#{$i} {
    border-radius: $i * 1px !important;
  }
  .font-#{$i} {
    font-size: ($i/4) * 1rem;
  }
}

@for $i from 1 through 10 {
  .border-#{$i} {
    border-width: $i * 1px !important;
  }
}
.text-wrap {
  white-space: pre-wrap;
}

.overflow-scroll {
  overflow: scroll;
}

.team-section {
  .meet-the-team {
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
  }

  .meet-the-team-description {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
  }

  .team-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .card {
      background: #f6f6f6;
      flex: 1;
      min-width: 150px;
      max-width: 190px;
      border-color: transparent;

      img {
        min-width: 120px;
        max-width: 190px;
        border-radius: 10px;
        object-fit: cover;
        overflow: hidden;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      }

      h5 {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
      }

      p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
      }
    }
  }
}

@for $i from 1 through 1000 {
  .max-width-#{$i}-px {
    max-width: $i * 1px;
  }
  .max-height-#{$i}-px {
    max-height: $i * 1px;
  }
}
@for $i from 1 through 100 {
  .max-width-#{$i} {
    max-width: $i * 1%;
  }
  .max-width-#{$i}- {
    max-width: $i * 1%;
  }
  .max-height-#{$i} {
    max-height: $i * 1%;
  }
  .height-#{$i} {
    height: $i * 1%;
  }
  .width-#{$i} {
    width: $i * 1%;
  }
}

#product {
  .product-card {
    background: #fafafa;
    padding: 4rem;
    margin-top: 3rem;
    border-color: transparent;
  }
}
#problem,
#overview {
  background: linear-gradient(to right, #f4f2f0, #faf8f8);
}
#overview {
  max-width: 1400px;
}
footer.section {
  background: rgba(0, 0, 0, 0.6);
  .app-info {
    min-width: 400px;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    .app-info {
      flex: 1;
    }
  }
}

.margin-auto {
  margin: auto !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.navbar-nav .nav-item a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hero-area {
  padding: 140px 0 0px 0;
}

#home {
  background-image: linear-gradient(to right, #f4f2f0, #faf8f8);
  padding-bottom: 100px;
}

@media only screen and (max-width: 800px) {
  #overview {
    background-image: none !important;
  }
}

#contact-page #stripe-page {
  background-color: #f4f2f0;
  form {
    text-align: left;
  }
  label {
    text-align: left;
  }
}

#coming-page {
  background-color: #f4f2f0;
  min-height: 80vh;
}

.nav-link.is-active {
  color: #ff6200 !important;
  border-bottom: 1px solid #ff6200 !important;
}

#contact-page {
  max-width: 1100px;
  margin: auto;
}
#contact-page h3 {
  color: #424242;
}
#contact-page .form-control {
  border-color: white;
  border-radius: 10px;
}
#contact-page .contact-info {
  color: #424242;
}

.main-content {
  min-height: calc(100vh - 210px);
  background-color: #f4f2f0;
}

//Desktop
@media (min-width: 767px) {
  .signup-form {
    margin-top: 7.5rem;
    padding-left: 5rem;
    border-left: 0.5px solid #4a4a4a;
  }
}

//Mobile
@media (max-width: 767px) {
  .signup-form {
    margin-bottom: 7.5rem;
  }
}

.signup-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
}

//Desktop
@media (min-width: 767px) {
  .church-card {
    .church-card-logo {
      width: 7rem;
      z-index: 5;
    }
    .church-card-body {
      width: 15rem;
      height: 15rem;
      margin-top: -3.5rem;
      border-radius: 0.5rem;
      background-size: cover;
    }

    .church-card-details {
      opacity: 0.8;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background-image: linear-gradient(#232322, #5b5b5b);
      margin-top: -2rem;
    }
    .church-card-inner-text {
      color: white;
      text-align: center;
      letter-spacing: 0px;
      font: normal normal medium 26px/31px SF Pro Text;
      opacity: 1;
    }
    .church-card-outer-text {
      display: none;
    }
  }
}

//Mobile
@media (max-width: 767px) {
  .create-account-text {
    display: none;
  }
  .church-card {
    .church-card-logo {
      display: none;
    }
    .church-card-body {
      width: 10rem;
      height: 10rem;
      border-radius: 0.5rem;
      background-size: cover;
      filter: drop-shadow(4px 4px 25px #00000029);
    }
    .church-card-inner-text {
      display: none;
    }
  }
}

.radius-10 {
  border-radius: 10px;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

.primary-svg {
  filter: invert(45%) sepia(82%) saturate(3744%) hue-rotate(2deg)
    brightness(105%) contrast(102%);
}
#contact-page form {
  // border-right: 1px solid #4a4a4a;
}
.button {
  background-color: #e34b0f;
  border-color: #e34b0f;
  color: rgba(255, 255, 255, 1);
  width: 9rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border-radius: 1rem;
  align-self: center;
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.btn:hover {
  color: #f4f2f0 !important;
}

.code-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code-label {
  margin-bottom: 16px;
}
.code-inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.code-inputs input {
  border: none;
  background-image: none;
  background-color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 4.5rem;
  width: 4rem;
  box-shadow: 0.065rem 0.065rem 0.3rem #00000042;
  border-radius: 0.625rem;
  opacity: 1;
  margin: 0 0.5rem;
  font-size: 2.4rem;
}
.code-inputs input:focus {
  outline: none;
  border: 1px solid #ff6200;
}

.flex-1 {
  flex: 1;
}

.stripe-wrapper p,
h3 {
  margin: 1rem;
}

.stripe-image-wrapper {
  margin: 3rem;
}

.stripe-image-wrapper,
img {
  border-radius: 2rem;
}
