#terms-page {
  p {
    padding-right: 0 !important;
  }
  div[id^="page_"] {
    max-width: 100%;
  }
  div[id^="id"] {
    max-width: 100%;
  }
  #page_1 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_1 #p1dimg1 {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 580px;
    height: 386px;
  }

  #page_1 #p1dimg1 #p1img1 {
    width: 580px;
    height: 386px;
  }

  #page_2 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_2 #id2_1 {
    border: none;
    margin: 0px 0px 0px 2px;
    padding: 0px;
    border: none;
    width: 696px;
    overflow: hidden;
  }

  #page_2 #id2_2 {
    border: none;
    margin: 6px 0px 0px 2px;
    padding: 0px;
    border: none;
    width: 580px;
    overflow: hidden;
  }

  #page_2 #p2dimg1 {
    position: absolute;
    top: 239px;
    left: 0px;
    z-index: -1;
    width: 580px;
    height: 453px;
  }

  #page_2 #p2dimg1 #p2img1 {
    width: 580px;
    height: 453px;
  }

  #page_3 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 696px;
  }

  #page_4 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_4 #p4dimg1 {
    position: absolute;
    top: 710px;
    left: 0px;
    z-index: -1;
    width: 580px;
    height: 1px;
    font-size: 1px;
    line-height: nHeight;
  }

  #page_4 #p4dimg1 #p4img1 {
    width: 580px;
    height: 1px;
  }

  #page_5 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_5 #id5_1 {
    border: none;
    margin: 0px 0px 0px 2px;
    padding: 0px;
    border: none;
    width: 696px;
    overflow: hidden;
  }

  #page_5 #id5_2 {
    border: none;
    margin: 29px 0px 0px 2px;
    padding: 0px;
    border: none;
    width: 562px;
    overflow: hidden;
  }

  #page_5 #p5dimg1 {
    position: absolute;
    top: 193px;
    left: 0px;
    z-index: -1;
    width: 580px;
    height: 555px;
  }

  #page_5 #p5dimg1 #p5img1 {
    width: 580px;
    height: 555px;
  }

  #page_6 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_6 #p6dimg1 {
    position: absolute;
    top: 100px;
    left: 0px;
    z-index: -1;
    width: 580px;
    height: 427px;
  }

  #page_6 #p6dimg1 #p6img1 {
    width: 580px;
    height: 427px;
  }

  #page_7 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_7 #p7dimg1 {
    position: absolute;
    top: 252px;
    left: 0px;
    z-index: -1;
    width: 580px;
    height: 387px;
  }

  #page_7 #p7dimg1 #p7img1 {
    width: 580px;
    height: 387px;
  }

  #page_8 {
    position: relative;
    overflow: hidden;
    margin: auto;
    padding: 0px;
    border: none;
    width: 800px;
  }

  #page_8 #p8dimg1 {
    position: absolute;
    top: 48px;
    left: 118px;
    z-index: -1;
    width: 580px;
    height: 679px;
  }

  #page_8 #p8dimg1 #p8img1 {
    width: 580px;
    height: 679px;
  }

  .dclr {
    clear: both;
    float: none;
    height: 1px;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }

  .ft0 {
    text-align: center;
    font: bold 37px "Arial";
    color: #17365d;
    line-height: 44px;
  }

  .ft1 {
    font: bold 15px "Arial";
    line-height: 18px;
  }

  .ft2 {
    font: bold 35px "Arial";
    color: #17365d;
    line-height: 41px;
  }

  .ft3 {
    font: bold 17px "Arial";
    color: #4f81bd;
    line-height: 19px;
  }

  .ft4 {
    font: bold 15px "Arial";
    line-height: 19px;
  }

  .ft5 {
    font: bold 13px "Arial";
    line-height: 19px;
  }

  .ft6 {
    font: bold 14px "Arial";
    line-height: 20px;
  }

  .ft7 {
    font: bold 13px "Arial";
    line-height: 20px;
  }

  .ft8 {
    font: bold 12px "Arial";
    line-height: 20px;
  }

  .ft9 {
    font: bold 9px "Arial";
    line-height: 11px;
  }

  .ft10 {
    font: bold 15px "Arial";
    margin-left: 17px;
    line-height: 18px;
  }

  .ft11 {
    font: bold 15px "Arial";
    color: #4f81bd;
    line-height: 18px;
  }

  .ft12 {
    font: bold 15px "Arial";
    line-height: 20px;
  }

  .ft13 {
    font: bold 15px "Arial";
    margin-left: 17px;
    line-height: 19px;
  }

  .ft14 {
    font: bold 15px "Arial";
    margin-left: 17px;
    line-height: 20px;
  }

  .ft15 {
    font: bold 12px "Arial";
    line-height: 21px;
  }

  .ft16 {
    font: bold 14px "Arial";
    color: #ff6600;
    line-height: 16px;
  }

  .ft17 {
    font: bold 14px "Arial";
    color: #333333;
    line-height: 16px;
  }

  .p0 {
    text-align: center;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .p1 {
    text-align: left;
    padding-left: 2px;
    margin-top: 22px;
    margin-bottom: 0px;
  }

  .p2 {
    text-align: left;
    padding-left: 2px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p3 {
    text-align: left;
    padding-left: 2px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p4 {
    text-align: left;
    padding-left: 2px;
    padding-right: 139px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .p5 {
    text-align: left;
    padding-left: 2px;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .p6 {
    text-align: left;
    padding-left: 2px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .p7 {
    text-align: left;
    padding-left: 50px;
    padding-right: 122px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p8 {
    text-align: left;
    padding-left: 50px;
    padding-right: 121px;
    margin-top: 4px;
    margin-bottom: 0px;
  }

  .p9 {
    text-align: left;
    padding-left: 50px;
    padding-right: 121px;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .p10 {
    text-align: left;
    padding-left: 50px;
    padding-right: 139px;
    margin-top: 4px;
    margin-bottom: 0px;
  }

  .p11 {
    text-align: left;
    padding-left: 50px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .p12 {
    text-align: left;
    padding-left: 50px;
    padding-right: 179px;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .p13 {
    text-align: left;
    padding-left: 50px;
    padding-right: 184px;
    margin-top: 1px;
    margin-bottom: 0px;
  }

  .p14 {
    text-align: left;
    padding-left: 50px;
    padding-right: 281px;
    margin-top: 4px;
    margin-bottom: 0px;
  }

  .p15 {
    text-align: left;
    padding-left: 48px;
    padding-right: 122px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p16 {
    text-align: justify;
    padding-left: 48px;
    padding-right: 124px;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .p17 {
    text-align: justify;
    padding-left: 48px;
    padding-right: 138px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p18 {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p19 {
    text-align: left;
    padding-right: 117px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p20 {
    text-align: left;
    padding-right: 134px;
    margin-top: 13px;
    margin-bottom: 0px;
  }

  .p21 {
    text-align: left;
    padding-right: 135px;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .p22 {
    text-align: left;
    padding-right: 121px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p23 {
    text-align: left;
    padding-right: 118px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p24 {
    text-align: left;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .p25 {
    text-align: left;
    padding-right: 124px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p26 {
    text-align: left;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p27 {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p28 {
    text-align: left;
    padding-right: 121px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p29 {
    text-align: left;
    padding-right: 161px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p30 {
    text-align: left;
    padding-right: 125px;
    margin-top: 13px;
    margin-bottom: 0px;
  }

  .p31 {
    text-align: left;
    margin-top: 14px;
    margin-bottom: 0px;
  }

  .p32 {
    text-align: left;
    padding-right: 121px;
    margin-top: 6px;
    margin-bottom: 0px;
  }

  .p33 {
    text-align: left;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .p34 {
    text-align: left;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .p35 {
    text-align: left;
    padding-right: 146px;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .p36 {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .p37 {
    text-align: left;
    padding-right: 180px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .p38 {
    text-align: left;
    padding-right: 148px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p39 {
    text-align: left;
    padding-right: 124px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p40 {
    text-align: left;
    padding-right: 123px;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .p41 {
    text-align: left;
    padding-left: 24px;
    padding-right: 148px;
    margin-top: 2px;
    margin-bottom: 0px;
    text-indent: -24px;
  }

  .p42 {
    text-align: left;
    padding-left: 24px;
    padding-right: 167px;
    margin-top: 3px;
    margin-bottom: 0px;
    text-indent: -24px;
  }

  .p43 {
    text-align: left;
    padding-left: 26px;
    padding-right: 148px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -24px;
  }

  .p44 {
    text-align: left;
    padding-left: 26px;
    padding-right: 140px;
    margin-top: 2px;
    margin-bottom: 0px;
    text-indent: -24px;
  }

  .p45 {
    text-align: left;
    padding-left: 2px;
    margin-top: 14px;
    margin-bottom: 0px;
  }

  .p46 {
    text-align: left;
    padding-left: 2px;
    padding-right: 138px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .p47 {
    text-align: left;
    padding-left: 2px;
    padding-right: 121px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p48 {
    text-align: left;
    padding-left: 2px;
    padding-right: 129px;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .p49 {
    text-align: left;
    padding-left: 2px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p50 {
    text-align: left;
    padding-left: 2px;
    padding-right: 151px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .p51 {
    text-align: left;
    padding-left: 2px;
    padding-right: 134px;
    margin-top: 13px;
    margin-bottom: 0px;
  }

  .p52 {
    text-align: left;
    padding-left: 2px;
    padding-right: 123px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p53 {
    text-align: left;
    padding-left: 2px;
    padding-right: 139px;
    margin-top: 13px;
    margin-bottom: 0px;
  }

  .p54 {
    text-align: left;
    padding-right: 118px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p55 {
    text-align: left;
    padding-right: 130px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p56 {
    text-align: left;
    padding-right: 121px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p57 {
    text-align: left;
    padding-right: 140px;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .p58 {
    text-align: left;
    padding-right: 127px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p59 {
    text-align: left;
    padding-right: 149px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p60 {
    text-align: left;
    padding-right: 131px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p61 {
    text-align: left;
    padding-right: 134px;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .p62 {
    text-align: left;
    padding-left: 2px;
    padding-right: 159px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p63 {
    text-align: left;
    padding-left: 2px;
    padding-right: 118px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p64 {
    text-align: left;
    padding-left: 2px;
    padding-right: 125px;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .p65 {
    text-align: left;
    padding-left: 2px;
    padding-right: 133px;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .p66 {
    text-align: left;
    padding-left: 2px;
    padding-right: 117px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p67 {
    text-align: left;
    padding-left: 2px;
    padding-right: 145px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .p68 {
    text-align: left;
    padding-left: 2px;
    padding-right: 128px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p69 {
    text-align: left;
    padding-left: 2px;
    padding-right: 125px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p70 {
    text-align: left;
    padding-left: 2px;
    margin-top: 13px;
    margin-bottom: 0px;
  }

  .p71 {
    text-align: left;
    padding-left: 2px;
    padding-right: 127px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p72 {
    text-align: left;
    padding-left: 2px;
    padding-right: 145px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p73 {
    text-align: left;
    padding-left: 2px;
    padding-right: 135px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p74 {
    text-align: left;
    padding-left: 2px;
    padding-right: 139px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p75 {
    text-align: left;
    padding-left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .p76 {
    text-align: left;
    padding-left: 0;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p77 {
    text-align: left;
    padding-left: 0;
    padding-right: 122px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .p78 {
    text-align: left;
    padding-left: 0;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p79 {
    text-align: left;
    padding-left: 0;
    padding-right: 124px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .p80 {
    text-align: left;
    padding-left: 0;
    padding-right: 136px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p81 {
    text-align: left;
    padding-left: 0;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p82 {
    text-align: left;
    padding-left: 0;
    padding-right: 124px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .p83 {
    text-align: justify;
    padding-left: 0;
    padding-right: 125px;
    margin-top: 17px;
    margin-bottom: 0px;
  }

  .p84 {
    text-align: left;
    padding-left: 0;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .p85 {
    text-align: left;
    padding-left: 0;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .p86 {
    text-align: right;
    padding-right: 0;
    margin-top: 21px;
    margin-bottom: 0px;
  }
}
